import React from "react";
import CustomButton from "../CustomButton";
import { Chevron } from "../../assets/icons";

const VerticalCard = ({
  data,
  icon,
  outlineColor,
  descriptionClass,
  darkMode,
}) => {
  return (
    <div className="flex flex-col justify-between py-6 sm:min-w-[564px] sm:py-10 rounded-2xl sm:rounded-2.5xl border dark:border-[#f0f0f04d] border-gray-1600 bg-white dark:bg-[#ffffff0d] px-4 vertical_card_width sm:px-11">
      <div className="flex flex-col gap-3.5 md:gap-4 ">
        <img
          src={darkMode ? data?.card_image_dark?.url : data?.card_image?.url}
          alt="img"
          className="rounded-xl sm:rounded-2xl sm:min-w-[476px]"
        />
        <div className="flex flex-row gap-1.5 mt-1 sm:mt-6 items-center">
          <img src={icon} width={28} height={28} alt="img" />
          <p
            className={`text-xl font-medium text-gray-2500 tracking-[-0.8px] `}
          >
            {data?.card_label?.text}
          </p>
        </div>

        <h1 className="font-manrope mx-0 tracking-[-1.2px] max-w-[476px] md:tracking-[-1.62px] mt-1 leading-9 font-semibold text-2xl sm:text-2.5xl  text-purple-1100 dark:text-white">
          {data?.card_heading?.text}
        </h1>
        <p
          className={`text-sm sm:text-lg leading-6 text-gray-2500  dark:text-gray-2200 font-normal tracking-tighter_3 font-inter  ${descriptionClass}`}
        >
          {data?.card_description?.text}
        </p>
      </div>
      <CustomButton
        text={data?.cta_text?.text}
        icon={<Chevron fill={`purple`} />}
        link={data?.cta_link?.url}
        direction={"right"}
        buttonClass={`bg-gray-1600 mt-5 sm:mt-10 hover:bg-gray-2900 dark:hover:bg-purple-1100 text-gray-2500 text-base dark:bg-[#9c8cff4d] dark:!text-purple-1900 tracking-tighter_2 `}
      />
    </div>
  );
};

export default VerticalCard;
