import * as React from "react";
import VideoComponent from "../VideoComponent";
import CustomButton from "../CustomButton";

const HorizontalCard = ({ data, openModal, isReverse }) => {
  const [item] = data?.items || [];
  return (
    <section
      className={`mx-auto justify-between flex lg:items-center overflow-hidden ${
        isReverse
          ? "lg:flex-row-reverse flex-col-reverse"
          : "lg:flex-row flex-col"
      }  lg:min-h-[416px] px-4 sm:px-6 lg:px-10 `}
    >
      <div className="flex lg:justify-center rounded-3xl">
        {item?.section_video?.url ? (
          <VideoComponent
            loop
            autoPlay
            muted
            className="w-full lg:max-w-[554px] h-auto mx-auto rounded-2xl"
          >
            <source src={videoSrc} type="video/mp4" />
          </VideoComponent>
        ) : (
          <img
            src={item?.section_image?.url}
            alt={item?.section_image?.alt || "img"}
            className="w-auto md:max-w-[554px] horizontal_margin-gatsby"
            loading="lazy"
          />
        )}
      </div>
      <div
        className={`flex flex-col gap-4 pt-6 pb-6 sm:pb-8 sm:pt-6 lg:py-10 px-4 sm:px-5 lg:max-w-[496px]`}
      >
        <div className="text-xs font-medium uppercase tracking-widest_1 text-purple-1500 pt-7.5 sm:text-base">
          {data?.primary?.label?.text}
        </div>
        <div className="flex flex-col gap-6">
          <h4 className="text-purple-1100 text-2xl font-manrope max-w-[265px] sm:max-w-[568px] lg:max-w-[468px] sm:text-2.5xl font-semibold leading-tight !tracking-tighter_3">
            {data?.primary?.section_heading?.text}
          </h4>
          <p className="text-sm sm:text-base text-gray-2500 tracking-tighter_1 sm:mr-22 lg:mr-10 font-inter">
            {data?.primary?.section_description?.text}
          </p>
          <CustomButton
            onClick={openModal}
            text={"Get started"}
            buttonClass="bg-purple-1500 text-white hover:bg-[#5E24FF]"
          />
        </div>
      </div>
    </section>
  );
};

export default HorizontalCard;
